import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Chip from '../../stories/general-components/Chip';
import GeneralText from '../../stories/general-components/GeneralText';

const CardInformationTag = ({ card, isBooking, from, langCode }) => {
  const { t } = useTranslation();

  // Helper function to round the number to the desired decimal places
  function roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      var arr = ('' + num).split('e');
      var sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      return +(
        Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) +
        'e-' +
        scale
      );
    }
  }

  return (
    <React.Fragment>
      <div className="align-left">
        <div className="mb-3">
          {card?.isInvoiced && !card?.isProject && (
            <Chip
              label={
                parseInt(card?.finances?.balance / 10000) <= 0
                  ? t('paid')
                  : t('unPaid')
              }
              status={parseInt(card?.finances?.balance / 10000) <= 0 ? 97 : 98}
              size="small"
            />
          )}
        </div>

        <div className="d-flex">
          <GeneralText
            size="bold"
            primary={true}
            fontSize="13px"
            text={card?.businessData?.['structurename_' + langCode]}
          />
          <GeneralText
            size="bold"
            primary={true}
            fontSize="12px"
            classNameComponent="uppercase"
            text={'#' + card?.id}
          />
        </div>
        <div className="d-flex mb-2">
          <GeneralText
            size="medium"
            primary={true}
            fontSize="11px"
            classNameComponent="uppercase"
            text={card?.name}
          />
        </div>
        <div className="d-flex">
          <GeneralText
            size="regular"
            primary={true}
            fontSize="11px"
            text={t('PO') + ': '}
          />
          <GeneralText
            size="regular"
            primary={true}
            fontSize="11px"
            classNameComponent="uppercase"
            text={
              card?.reference
                ? card?.reference + ' - ' + (card?.id?.slice(-6) ?? '')
                : card?.id?.slice(-6) ?? ''
            }
          />
        </div>

        <div className="mt-3">
          <GeneralText
            size="medium"
            primary={true}
            fontSize="10px"
            text={
              t('creationDate') +
              ': ' +
              moment
                .unix(card?.timeStamp?._seconds || card?.timeStamp?.seconds)
                .format('DD MMM YYYY')
            }
          />
        </div>
        <div>
          <GeneralText
            size="medium"
            primary={true}
            fontSize="10px"
            text={
              t('dueDate') +
              ': ' +
              moment
                .unix(
                  card?.dueDate?._seconds ||
                    card?.dueDate?.seconds ||
                    card?.targetDate?._seconds ||
                    card?.targetDate?.seconds
                )
                .format('DD MMM YYYY')
            }
          />
        </div>
        <div>
          <GeneralText
            size="bold"
            primary={true}
            fontSize="10px"
            text={
              (card?.targetProfileDetails?.attribute1 || '') +
              ' ' +
              (card?.targetProfileDetails?.attribute2 || '') +
              ' ' +
              (card?.targetProfileDetails?.attribute3 || '') +
              ' ' +
              (card?.targetProfileDetails?.attribute4 || '')
            }
          />
          {card?.targetProfileDetails?.metric && (
            <GeneralText
              size="bold"
              primary={true}
              fontSize="10px"
              text={' -> ' + card?.targetProfileDetails?.metric}
            />
          )}
          {card?.targetProfileDetails?.name && (
            <GeneralText
              size="medium"
              primary={true}
              fontSize="10px"
              text={card?.targetProfileDetails?.name || ''}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardInformationTag;
